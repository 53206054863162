import React, { Component } from "react";
import styles from "./track.module.css";
import Data from "./data";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";
import { CSSTransitionGroup } from "react-transition-group";

class Track extends Component {
  render(props) {
    return (
      <>
        {Data.map((item, index) => (
          <>
            <LazyLoad throttle={200} height={300}>
              <CSSTransitionGroup
                key="1"
                transitionName="fade"
                transitionAppear
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}
              >
                <div className={styles["container"]} key={item.day}>
                  <div className={styles["track-container"]}>
                    <hr />
                    <div className={styles["date"]}>
                      <span>{item.date}</span>
                      <h2>{item.day}</h2>
                    </div>
                    <div className={styles["track"]}>
                      {item.mixes.map((jimmy, index) => (
                        <div key={index}>
                          <div>
                            <h4>{jimmy.title}</h4>
                          </div>
                          <div className="playerWrapper">
                            <ReactPlayer
                              className="reactPlayer"
                              style={{
                                borderRadius: 8,
                                backgroundColor: "dimgray"
                              }}
                              url={jimmy.url}
                              width="100%"
                              height="100%"
                              controls="true"
                              onStart={this.props.partyModeOn}
                              onPause={this.props.partyModeOff}
                            />
                          </div>
                          <div className={styles["artists"]}>
                            Artists | {jimmy.artists}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </CSSTransitionGroup>
            </LazyLoad>
          </>
        ))}
      </>
    );
  }
}

export default Track;
