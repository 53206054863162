import React, { Component } from "react";
import "./App.css";
import Track from "./components/track";
import ReactGA from "react-ga";
ReactGA.initialize("UA-170113715-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partyMode: false,
      danceMode: false
    };
  }

  partyModeOn() {
    this.setState({ partyMode: true });
  }

  partyModeOff() {
    this.setState({ partyMode: false });
  }

  danceModeOn() {
    this.setState({ danceMode: true });
  }

  danceModeOff() {
    this.setState({ danceMode: false });
  }

  render() {
    return (
      <div className="App">
        <div class="outer-menu">
          <input class="checkbox-toggle" type="checkbox" />
          <div class="hamburger">
            <div></div>
          </div>
          <div class="menu">
            <div>
              <div>
                <ul>
                  <li>
                    <a href="mailto:info@casamusic.co.uk">Submit a mix</a>
                  </li>
                  <li>
                    {!this.state.danceMode ? (
                      <button
                        className="partyModeButton"
                        onClick={() => this.danceModeOn()}
                      >
                        Party mode <em>off</em>
                      </button>
                    ) : (
                      <button
                        className="partyModeButton"
                        onClick={() => this.danceModeOff()}
                      >
                        Party mode <em>on</em>
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            !this.state.danceMode ? "headerContainer" : "headerContainerParty"
          }
        >
          <span className="anim">
            <h1>
              Casamusic&nbsp;&nbsp;&nbsp;Casamusic&nbsp;&nbsp;&nbsp;Casamusic&nbsp;&nbsp;&nbsp;
              Casamusic&nbsp;&nbsp;&nbsp;Casamusic&nbsp;&nbsp;&nbsp;Casamusic
            </h1>
          </span>
        </div>
        <header
          className={
            !this.state.danceMode ? "App-header" : "App-header-playing"
          }
        >
          <div className="logo-container">
            <div className="logo">
              {!this.state.partyMode ? (
                <h1>
                  CASA
                  <br />
                  MUSIC
                </h1>
              ) : (
                <h1 className="playing">&#9835;</h1>
              )}
            </div>
          </div>
          <section className="player-container">
            <Track
              partyModeOn={this.partyModeOn.bind(this)}
              partyModeOff={this.partyModeOff.bind(this)}
            />
          </section>
          <div className="ripples">
            <div class="circle xxlarge shade1"></div>
            <div class="circle xlarge shade2"></div>
            <div class="circle large shade3"></div>
            <div class="circle mediun shade4"></div>
            <div class="circle small shade5"></div>
          </div>
        </header>
      </div>
    );
  }
}

export default App;
